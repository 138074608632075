<template>
  <div>
    <Spinner v-if="isLoading" />
    <div v-else>
      <vca-column class="short" v-for="role in allRoles" :key="role.value">
        <h4>{{ role.name }}:</h4>
        <vca-dropdown
          v-if="hasSystemPermission() || hasPoolPermission()"
          v-model="aspValues[role.value]"
          :multiple="true"
          :options="crewUser"
          label=""
          :placeholder="$t('crews.roles.add_user.label')"
        ></vca-dropdown>
        <span v-else>{{ getASPs(role.value) }}</span>
      </vca-column>
      <button
        v-if="hasSystemPermission() || hasPoolPermission()"
        class="vca-button"
        @click="updateRoles"
      >
        {{ $t("context_button.update", { 0: $t("context.roles") }) }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Spinner from "@/components/utils/Spinner.vue";
export default {
  name: "CrewAspBulk",
  components: { Spinner },
  data() {
    return {
      isLoading: false,
      crewAsps: [],
      crew_id: null,
      crewUser: [],
      aspValues: {},
      currentCrewRoles: {},
      allRoles: [
        {
          name: this.$t("network.roles.operation"),
          value: "operation",
        },
        { name: this.$t("network.roles.finance"), value: "finance" },
        {
          name: this.$t("network.roles.education"),
          value: "education",
        },
        { name: this.$t("network.roles.network"), value: "network" },
        {
          name: this.$t("network.roles.socialmedia"),
          value: "socialmedia",
        },
        {
          name: this.$t("network.roles.awareness"),
          value: "awareness",
        },
        { name: this.$t("network.roles.other"), value: "other" },
      ],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.crew_id = this.$route.params.id
        ? this.$route.params.id
        : this.user.crew.crew_id;
      let data = { crew_id: this.crew_id };
      this.isLoading = true;

      if (this.hasSystemPermission() || this.hasPoolPermission()) {
        data["nvm_state"] = "confirmed";
        data["limit"] = 500;
        this.$store
          .dispatch({ type: "users/queryList", data: data })
          .then((response) => {
            if (!response || response.length == 0) {
              console.error("Missing roles");
              return;
            }
            this.crewUser = response.filter((user) => {
              user.title = user.label = user.first_name + " " + user.last_name;
              user.subtitle = user.profile.mattermost_username
                ? this.$t("users.list.mattermost") +
                  ": " +
                  user.profile.mattermost_username
                : user.email;
              user.label +=
                " (" +
                (user.profile.mattermost_username
                  ? this.$t("users.list.mattermost") +
                    ": " +
                    user.profile.mattermost_username
                  : user.email) +
                ")";
              user.value = user.id;
              return user.nvm.status == "confirmed";
            });
            this.allRoles.forEach((role) => {
              if (this.hasPoolPermission() || this.hasSystemPermission()) {
                this.aspValues[role.value] = this.crewUser.filter((user) => {
                  return user.pool_roles.find((el) => el.name == role.value);
                });
              }
            });
            this.crewAsps = this.crewUser.filter((el) => {
              return el.pool_roles.length > 0;
            });
            this.isLoading = false;
          });
      } else {
        this.$store.dispatch({ type: "users/crew" }).then((response) => {
          if (!response || response.length == 0) {
            console.error("Missing roles");
            return;
          }
          this.crewUser = response.filter((user) => {
            user.title = user.label = user.first_name + " " + user.last_name;
            user.value = user.id;
            return user.nvm.status == "confirmed";
          });
          this.allRoles.forEach((role) => {
            this.aspValues[role.value] = this.crewUser.filter((user) => {
              return user.pool_roles.find((el) => el.name == role.value);
            });
            this.currentCrewRoles[role.value] = this.crewUser.filter((user) => {
              return user.pool_roles.find((el) => el.name == role.value);
            });
          });
          this.crewAsps = this.crewUser.filter((el) => {
            return el.pool_roles.length > 0;
          });
          this.isLoading = false;
        });
      }
    },
    getASPs(role) {
      let userList = this.crewAsps
        .filter((user) => {
          return user.pool_roles.find((el) => el.name == role);
        })
        .map(
          (user) =>
            user.first_name +
            " " +
            user.last_name +
            (this.hasSystemPermission() || this.hasPoolPermission()
              ? " (" +
                (user.profile.mattermost_username
                  ? this.$t("users.list.mattermost") +
                    ": " +
                    user.profile.mattermost_username
                  : user.email) +
                ")"
              : "")
        )
        .join(", ");
      return userList ? userList : " - ";
    },
    async updateRoles() {
      if (!confirm(this.$t("messages.role.selection_confirm"))) {
        return;
      }

      const addedRoles = [];

      this.allRoles.forEach((el) => {
        if (this.aspValues[el.value]) {
          this.aspValues[el.value].forEach((user) => {
            addedRoles.push({
              user_id: user.id,
              role: el.value,
            });
          });
        }
      });
      this.$store
        .dispatch({
          type: "users/roles_history/bulk",
          data: {
            crew_id: this.crew_id,
            created: addedRoles,
          },
        })
        .then(() => {
          this.$emit("reload");
          this.init();
        });
    },
  },
  computed: {
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
      hasPoolPermission: "user/roles/hasPoolPermission",
      user: "user/current",
      crew: "crews/current",
    }),
  },
};
</script>
