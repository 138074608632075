<template>
  <vca-column>
    <vca-card v-if="crew" class="shadowed">
      <vca-field :label="$t('crews.general')">
        <CrewForm
          @submit="update"
          @remove_city="removeCity"
          @add_city="addCity"
          v-if="hasSystemPermission()"
          v-model="crew"
        />
        <div v-else>
          <div>
            <h3>{{ $t("crews.name.label") }}</h3>
            <div>{{ getCurrent.name }}</div>
          </div>
          <div>
            <h3>{{ $t("crews.email.label") }}</h3>
            <div>{{ getCurrent.email }}</div>
          </div>
          <div>
            <h3>{{ $t("crews.abbreviation.label") }}</h3>
            <div>{{ getCurrent.abbreviation }}</div>
          </div>
          <div>
            <h3>
              {{ $t("crews.mattermost.label") }}
            </h3>
            <div v-if="!hasPoolPermission()">
              {{ getCurrent.mattermost_username }}
            </div>
            <div v-else>
              <vca-input
                v-model="getCurrent.mattermost_username"
                :placeholder="$t('crews.mattermost.placeholder')"
              />
            </div>
          </div>
          <div>
            <h3>{{ $t("crews.additional.label") }}</h3>
            <div v-if="!hasPoolPermission()" class="formatted">
              {{ getCurrent.additional }}
            </div>
            <div v-else>
              <vca-textarea
                v-model="getCurrent.additional"
                :placeholder="$t('crews.additional.placeholder')"
              />
              <div>
                <button class="vca-button" @click="update()">
                  {{ $t("button.save") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </vca-field>
    </vca-card>
    <vca-card v-if="crew" class="shadowed">
      <vca-field
        v-if="asp_selection == 'active'"
        :label="$t('crews.asps_selection.header')"
      >
        <p>{{ $t("crews.asps_selection.description") }}</p>
        <CrewAspSelectionBulk @reload="$emit('reload')" />
      </vca-field>
      <vca-field
        v-else-if="asp_selection == 'selected' && !hasSystemPermission()"
        :label="$t('crews.asps_selection.header')"
      >
        <p>{{ $t("crews.asps_selection.description") }}</p>
        <CrewAspBulkSelected />
        <vca-card class="shadowed"
          ><p>{{ $t("crews.asps_selection.requested") }}</p></vca-card
        >
      </vca-field>
      <vca-field
        v-else-if="asp_selection == 'selected' && hasSystemPermission()"
        :label="$t('crews.asps_selection.header')"
      >
        <p>{{ $t("crews.asps_selection.description") }}</p>
        <CrewAdminSelectionBulk @reload="$emit('reload')" />
      </vca-field>
      <vca-field v-else :label="$t('crews.asps')">
        <CrewAspBulk />
      </vca-field>
    </vca-card>

    <vca-card v-if="crew" class="shadowed">
      <vca-field :label="$t('crews.map.label')">
        <div>
          <div class="tags-container">
            <div class="tag" v-for="res in cityList" :key="res.city">
              <div class="tag-label">{{ res.city }} ({{ res.country }})</div>
            </div>
          </div>
        </div>
        <div>
          <vca-map
            :hideFields="true"
            ref="map"
            :hideButtons="cityList.length <= 1"
            :disabled="true"
            v-model="cityList"
          />
        </div> </vca-field
    ></vca-card>
  </vca-column>
</template>

<script>
import { mapGetters } from "vuex";
import CrewForm from "@/components/network/crews/CrewForm";
import CrewAspBulkSelected from "./CrewAspBulkSelected.vue";
import CrewAspBulk from "./CrewAspBulk.vue";
import CrewAspSelectionBulk from "./CrewAspSelectionBulk.vue";
import CrewAdminSelectionBulk from "./CrewAdminSelectionBulk.vue";
export default {
  name: "CrewAspView",
  components: {
    CrewForm,
    CrewAspBulk,
    CrewAspSelectionBulk,
    CrewAspBulkSelected,
    CrewAdminSelectionBulk,
  },
  methods: {
    removeCity(index) {
      this.$store.commit("crews/remove_current_city", index);
    },
    addCity(val) {
      if (this.crew.cities == undefined) {
        this.crew["cities"] = [];
      }
      if (this.crew.cities.find((el) => el.city == val.city)) {
        this.notification({
          title: this.$t("messages.title.warn"),
          body: this.$t("messages.crew.city_conflict"),
          type: "warn",
        });
        return;
      }
      this.$store.commit("crews/add_current_city", val);
    },
    update() {
      if (!confirm(this.$t("crews.messages.update"))) {
        return false;
      }
      this.$store.dispatch({ type: "crews/updateReq" }).then((response) => {
        this.crew = response;
        this.$store.commit(
          "crews/asp_selection_state",
          this.crew.asp_selection
        );
      });
    },
  },
  watch: {
    api_lang: {
      handler(value) {
        if (this.$refs.map) {
          this.$refs.map.setLanguage(value);
        }
      },
    },
  },
  computed: {
    crew: {
      get() {
        return this.$store.state.crews.current;
      },
      set(value) {
        this.$store.commit("crews/current", value);
      },
    },
    getCurrent() {
      return this.crew ? this.crew : {};
    },
    cityList: {
      get() {
        return this.crew
          ? this.crew.cities.filter((el) => {
              el.title = el.city;
              el.subtitle = "";
              return true;
            })
          : [];
      },
      set() {},
    },
    ...mapGetters({
      api_lang: "api_lang",
      hasSystemPermission: "user/roles/hasSystemPermission",
      hasPoolPermission: "user/roles/hasPoolPermission",
      user: "user/current",
      asp_selection: "crews/asp_selection_state",
    }),
  },
};
</script>
