<template>
  <vca-card>
    <h2>{{ $t("network.crews.view.header", { 0: crewName }) }}</h2>
    <CrewAspView ref="view" @reload="load" />
    <vca-popup
      v-if="current != null"
      :show="current != null"
      :title="$t('crews.popup.edit', { 0: current.name })"
      @close="current = null"
    >
      {{ current }}
    </vca-popup>
  </vca-card>
</template>
<script>
import { mapGetters } from "vuex";
import CrewAspView from "@/components/network/crews/asp/CrewAspView";
export default {
  name: "CrewDisplayView",
  components: { CrewAspView },
  data() {
    return { current: null };
  },
  computed: {
    crewName() {
      return this.crew ? this.crew.name : "";
    },
    crew: {
      get() {
        return this.$store.state.crews.current;
      },
      set(value) {
        this.$store.commit("crews/current", value);
      },
    },
    ...mapGetters({
      user: "user/current",
      hasSystemPermission: "user/roles/hasSystemPermission",
      findById: "crews/findById",
    }),
  },
  watch: {
    "$route.params.id": {
      handler() {
        this.load();
      },
      deep: true,
    },
  },
  created() {
    this.$store.dispatch({ type: "refresh" });
    this.load();
  },
  methods: {
    load() {
      this.crew = null;
      if (!this.hasSystemPermission()) {
        this.$store.dispatch({ type: "crews/own" }).then(() => {
          if (!this.crew) {
            this.$router.push({ name: "NotFoundView" });
          }
          this.$store.commit(
            "crews/asp_selection_state",
            this.crew.asp_selection
          );
        });
      } else {
        this.$store.dispatch({ type: "crews/list" }).then(() => {
          this.crew = this.findById(
            this.$route.params.id
              ? this.$route.params.id
              : this.user.crew.crew_id
          );
          this.$store.commit(
            "crews/asp_selection_state",
            this.crew.asp_selection
          );
        });
      }
    },
  },
};
</script>
